import React, { useState, useMemo, useContext } from 'react';
import { useIntl } from 'react-intl';
import { FiMapPin } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useGet } from '~hooks/useQuery';
import { getDistanceFormatted, nl2br, renderFirstName } from '~helpers/common';
import { UserContext } from '~contexts/user';
import ServerContext from '~src/serverContext';

import Avatar from '~components/avatar';
import ReviewsList from '~components/review/list';
import Stars from '~components/stars';
import LastOnline from '~components/last-online';
import VerificationBadge from '~components/verification/badge';
import Button from '~components/button';
import Share from '~components/share';
import Favorite from '~components/favorite';

import ProfileSkeleton from '../skeleton';

import '../../style.scss';
import '../style.scss';

const HOUR = 3600;

const CustomerProfile = ({
  isLoading,
  personId,
  firstName,
  profilePicture,
  personalNote,
  score,
  lastLogin,
  isOnline,
  registerDate,
  verification,
  interval,
  duration,
  days,
  location,
  published,
}) => {

  const intl = useIntl();
  const history = useHistory();

  const domainConfig = useSelector((state) => state.domainConfig);
  const { user } = useContext(UserContext);
  const userLocation = user?.wish?.location;
  const cache = useContext(ServerContext);

  const [isShareOpened, setShareOpen] = useState(false);

  const distance = useMemo(
    () =>
      getDistanceFormatted(location?.latitude, location?.longitude, userLocation?.latitude, userLocation?.longitude),
    [location, userLocation]
  );

  const { data: reviews, isLoading: isLoadingReviews } = useGet(
    {
      name: `reviews`,
      requiresAuthentication: false,
      shouldExecute: !!personId,
      mockFileName: 'reviews/get-by-receiver',
      cacheData: cache.get('serverCustomerReviews'),
      params: {
        'receiver-id': personId,
        // limit: 10,
        // offset: 0,
      },
    },
    [personId]
  );

  const fName = renderFirstName(firstName);

  const handleChat = () => {
    history.push({ pathname: `/discussion/${personId}` });
  };

  return (
    <>
      {isLoading ? (
        <ProfileSkeleton />
      ) : (
        <div className="c-profile c-profile--page">
          <div className="c-profile__picture">
            <Avatar profilePicture={profilePicture} firstName={fName} size="large" />
          </div>
          <div className="c-profile__header">
            <div className="c-profile__name l-headline--secondary notranslate">
              {fName}
              <VerificationBadge />
            </div>
            <div className="c-profile__score">
              <Stars value={score?.score || 4} />
              {/* <Stars value={score?.score} disabled={!score || !score.score} /> */}
              {score && score.votes > 0 ? <div className="c-profile__votes">({score.votes})</div> : null}
            </div>
            {/* ADDRESS */}
            {location && location.city ? (
              <div className="c-profile__address">
                <FiMapPin />
                <span>{`${[location?.city, location?.country].filter(Boolean).join(', ')} ${distance ? `(${distance} km)` : ''}`}</span>
              </div>
            ) : null}
          </div>
          <div className="c-profile__features">
            <div className="c-profile__feature">
              <div className="c-profile__key">
                {intl.formatMessage({
                  id: 'containers.profile.customer.active-since.label',
                  defaultMessage: 'Active since',
                })}
              </div>
              <div className="c-profile__value">
                {registerDate
                  ? intl.formatDate(moment.unix(registerDate).toDate(), {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                  : '-'}
              </div>
            </div>
            <div className="c-profile__feature">
              <div className="c-profile__key">
                {intl.formatMessage({
                  id: 'containers.profile.customer.last-online.label',
                  defaultMessage: 'Last online',
                })}
              </div>
              <div className="c-profile__value">
                <LastOnline lastOnline={lastLogin} isOnline={isOnline} isPublished={published} />
              </div>
            </div>
            <div className="c-profile__feature">
              <div className="c-profile__key">
                {intl.formatMessage({ id: 'containers.profile.customer.service.label', defaultMessage: 'Service' })}
              </div>
              <div className="c-profile__value">
                {interval
                  ? `${intl.formatMessage({ id: `interval.every-${interval}-weeks` })}, ${intl.formatMessage(
                      {
                        id: 'containers.profile.customer.duration',
                        defaultMessage: '{duration, plural, =1 {# hour} other { # hours}}',
                      },
                      { duration: duration / HOUR }
                    )}`
                  : '-'}
              </div>
            </div>
            <div className="c-profile__feature">
              <div className="c-profile__key">
                {intl.formatMessage({ id: 'containers.profile.customer.days.label', defaultMessage: 'Possible days' })}
              </div>
              <div className="c-profile__value">
                {days
                  ? days.map(
                      (d, index) =>
                        `${intl.formatMessage({ id: `days.short.${d}` })}${index !== days.length - 1 ? ', ' : ''}`
                    )
                  : '-'}
              </div>
            </div>
          </div>
          <div className="c-profile__about">
            <div className="l-headline--tertiary">
              {intl.formatMessage({ id: 'containers.profile.customer.about', defaultMessage: 'About' })}
            </div>
            <p>{personalNote ? nl2br(personalNote) : '-'}</p>
          </div>
          <ReviewsList
          isLoading={isLoadingReviews}
          reviews={reviews?.data}
          className="c-profile__reviews"
          headline={intl.formatMessage({ id: 'containers.profile.customer.reviews', defaultMessage: 'Customer reviews' })}
        />
          <div className="c-profile__footer">
            <div className="c-profile__bottom-buttons">
              <Button onClick={handleChat}>
                {intl.formatMessage({ id: 'containers.profile.customer.buttons-bottom.chat', defaultMessage: 'Chat' })}
              </Button>
              <Favorite accountType="client" personId={personId} />
            </div>
          </div>
          <Share
            sharedUrl={`${domainConfig.protocol}://${domainConfig.domain}/customer/${personId}`}
            title={intl.formatMessage(
              {
                id: 'containers.profile.seo.customer.share-title',
                defaultMessage: '{firstName} customer profile - #website',
              },
              {
                firstName: fName,
              }
            )}
            isOpened={isShareOpened}
            onToggle={() => setShareOpen(false)}
          />
        </div>
      )}
    </>
  );
};

export default CustomerProfile;
